body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.importantCard {
  width: 20%;
  margin: auto;
}

.registerForm {
  display: grid;
  grid-template-areas: 
    	"email email"
      "firstName lastName"
      "password repeatPassword"
      "save save"
      "submit submit"
  ;
  grid-gap: 10px;
}

.tinyForm {
  margin: auto;
  width: 50%;
}

@media only screen and (max-width: 780px) {
  .importantCard {
    width: 80%;
    margin: auto;
  }

  .registerForm {
    display: grid;
    grid-template-areas: 
        "email"
        "firstName"
        "lastName"
        "password"
        "repeatPassword"
        "save"
        "submit"
    ;
    grid-gap: 10px;
  }

  .tinyForm {
    margin: auto;
    width: 80%;
  }
}